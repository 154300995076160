<template>
  <div id="app">
    <HeaderComponent />
    <main class="content-section">
      <div class="left-column">
        <div class="right-column-overlay" v-if="showOverlay">
          <router-view name="rightColumn" />
        </div>
        <router-view />
        <div class="right-column-overlay" v-if="showAsFooter">
          <router-view name="rightColumn" />
        </div>
      </div>
      <div class="right-column" v-if="showRightColumn">
        <router-view name="rightColumn" />
      </div>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './common/components/HeaderComponent.vue';
import FooterComponent from './common/components/FooterComponent.vue';

export default {
  name: 'AppComponent',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    showRightColumn() {
      return this.windowWidth > 1200;
    },
    showOverlay() {
      return this.windowWidth <= 1200 && this.windowWidth >= 768;
    },
    showAsFooter() {
      return this.windowWidth < 768;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    document.title = 'Evangelische Miriamgemeinde Frankfurt';
  },
  beforeUnmount() { // eslint-disable-line vue/no-deprecated-destroyed-lifecycle
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style>
/* eslint-disable */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-section {
  flex: 1;
  display: flex;
  color: #000;
  overflow-y: hidden;
}

.left-column {
  flex: 1;
  min-width: 1000px;
  margin: 0.5em 0 0.5em 0.5em;
  padding-right: 0.5em;
  background-color: #ffffff;
  overflow-y: auto;
}

.right-column {
  flex: 1;
  background-color: #d9e2f3;
  overflow-y: auto;
  margin: 0 7px 7px 0;
}

.right-column h2 {
  text-align: center;
  color: #000;
}

.right-column img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.right-column-overlay {
  max-height: 150px;
  overflow-y: hidden;
  margin-top: 10px;
  background-color: #d9e2f3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-column-overlay img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .content-section {
    flex-direction: column;
  }

  .left-column {
    padding: 10px;
  }

  .right-column {
    display: none;
  }

  .right-column-overlay {
    display: flex;
    max-height: 150px;
    background-color: #ffffff;
  }

  .right-column-overlay img {
    max-height: 150px;
    max-width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 767px) {
  .content-section {
    flex-direction: column;
  }

  .left-column,
  .right-column {
    padding: 10px;
  }

  .right-column {
    display: none;
  }

  .right-column-overlay {
    display: flex;
  }
}
/* eslint-enable */
</style>
