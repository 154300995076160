import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

function loadComponent(componentName) {
  return () => {
    if (!componentName) {
      return import('./content/DefaultComponent.vue'); 
    }
    return import(`./content/${componentName}.vue`);
  };
}

function generateRoutesFromMenu(menu) {
  let routes = [];
  menu.forEach(item => {
    if (item.link === '/admin/edit-pages') {
      return;
    }

    let route = null;
    if (item.link && item.link !== '') {
      route = {
        path: item.link,
        meta: { requiresAuth: item.requiresAuth || false },
        components: {
          default: loadComponent(item.component),
          rightColumn: loadComponent('ImageContent')
        }
      };
    }

    if (item.submenu && item.submenu.length > 0) {
      let children = generateRoutesFromMenu(item.submenu);
      routes.push(...children);
    }
    if (route) {
      routes.push(route);
    }
  });
  return routes;
}

const router = createRouter({
  history: createWebHistory(),
  routes: []
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.menuData.length) {
    await store.dispatch('loadMenuData');
    const routes = generateRoutesFromMenu(store.state.menuData);
    routes.forEach(route => router.addRoute(route));
    addEditPagesRoute();
    router.addRoute({
      path: '/:pathMatch(.*)*',
      components: {
        default: loadComponent('DefaultComponent'),
        rightColumn: loadComponent('ImageContent')
      }
    });

    next({ ...to, replace: true });
  } else {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isLoggedIn) {
      next('/login');
    } else {
      next();
    }
  }
});

function addEditPagesRoute() {
  if (router.hasRoute('/admin/edit-pages')) {
    router.removeRoute('/admin/edit-pages');
  }
  router.addRoute({
    path: '/admin/edit-pages',
    components: {
      default: loadComponent('admin/PagePreviewComponent'),
      rightColumn: loadComponent('admin/EditPagesComponent')
    },
    name: 'admin-edit-pages'
  });
}

addEditPagesRoute();

export default router;
