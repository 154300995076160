<template>
  <header>
    <div class="header-title">
      <h1>Evangelische Miriamgemeinde Frankfurt am Main</h1>
      <span class="reload-icon" @click="reloadMenu">&#x27F3;</span>
    </div>
    <NavbarComponent />
  </header>
</template>

<script>
import NavbarComponent from './NavbarComponent.vue';
import { mapActions } from 'vuex';
import router from '@/router'; // Importieren Sie den Router

export default {
  name: 'HeaderComponent',
  components: {
    NavbarComponent
  },
  methods: {
    ...mapActions(['loadMenuData']),
    async reloadMenu() {
      await this.loadMenuData();
      this.$router.push({ path: '/' }); // Zurück zur Startseite oder eine andere Seite, um sicherzustellen, dass der Router neu geladen wird
      const routes = this.$store.state.menuData.map(item => {
        if (item.component) {
          return {
            path: item.link,
            component: () => import(`../components/${item.component}.vue`)
          };
        }
      });
      routes.forEach(route => router.addRoute(route)); // Neue Routen hinzufügen
    }
  }
};
</script>

<style scoped>
header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: .3em .5em;
}

header h1 {
  margin: 0;
  flex: 1;
  text-align: center;
  text-shadow: 2px 2px 1px #e0bfff;
  padding-bottom: 4px;
}

.reload-icon {
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  background-color: #e0bfff;
  color: white;
  padding: 5px;
  border-radius: 50%;
}

.reload-icon:hover {
  color: #7a00d1;
}
</style>
