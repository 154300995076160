import { createStore } from 'vuex';
import axios from 'axios';
import router from '../router'; 

let user = [];
try {
  user = JSON.parse(localStorage.getItem('user')) || null;
} catch (e) {
  user = [];
}

export default createStore({
  state: {
    isLoggedIn: !!localStorage.getItem('isLoggedIn'),
    user: user,
    token: localStorage.getItem('token') || '',
    menuData: [],
    pageContent: '',
    pageTitle: '',
    selectedPage: '',
  },
  mutations: {
    setLogin(state, { user, token }) {
      state.isLoggedIn = true;
      state.user = user;
      state.token = token;
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.token = '';
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      router.push('/');
    },
    setMenuData(state, menuData) {
      state.menuData = menuData;
    },
    SET_PAGE_CONTENT(state, content) {
      state.pageContent = content;
    },
    UPDATE_PAGE_CONTENT(state, content) {
      state.pageContent = content;
    },
    setPageTitle(state, title) {
      state.pageTitle = title;
    },
    setSelectedPage(state, page) {
      state.selectedPage = page;
    },
  },
  actions: {
    async loadMenuData({ commit }) {
      try {
        const response = await axios.get('/menu-data');
        commit('setMenuData', response.data);
      } catch (error) {
        console.error('Fehler beim Laden der Menü-Daten:', error);
      }
    },
    async loadPageContent({ commit }, link) {
      try {
        const response = await axios.get(`/page-content?link=${link}`);
        commit('SET_PAGE_CONTENT', response.data.content || '');
      } catch (error) {
        console.error('Fehler beim Laden des Seiteninhalts:', error);
      }
    },
    async savePageContent({ state }, { link, name }) {
      try {
        const contentToSave = state.pageContent;

        await axios.post('/page-content', {
          link,
          name,
          content: contentToSave,
        });

        alert('Seiteninhalt gespeichert!');
      } catch (error) {
        console.error('Fehler beim Speichern des Seiteninhalts:', error);
      }
    },
    setPageTitle({ commit }, title) {
      commit('setPageTitle', title);
    },
    setSelectedPage({ commit }, page) {
      commit('setSelectedPage', page);
    },
    login({ commit }, { user, token }) {
      commit('setLogin', { user, token });
    },
    logout({ commit }) {
      commit('logout');
    }
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    user: state => state.user,
    menuData: state => state.menuData,
    pageContent: state => state.pageContent,
    pageTitle: state => state.pageTitle,
    selectedPage: state => state.selectedPage,
  }
});
