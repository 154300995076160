<template>
  <nav class="navbar">
    <button class="menu-toggle" @click="toggleMenu">
      Menü
    </button>
    <ul v-if="isMenuOpen || windowWidth > 768">
      <li v-for="item in menu" :key="item.name" @click="toggleSubmenu(item.name)">
        <router-link :to="item.link" v-if="item.link" @click="closeMenu">
          {{ item.name }}
        </router-link>
        <span v-if="!item.link" class="pointer">
          {{ item.name }}
        </span>
        <transition name="fade">
          <div v-if="item.submenu && item.submenu.length && (isSubmenuOpen[item.name] || windowWidth > 768)"
            class="dropdown-content">
            <router-link v-for="subitem in item.submenu" :key="subitem.name" :to="subitem.link" @click="closeMenu">
              {{ subitem.name }}
            </router-link>
          </div>
        </transition>
      </li>
    </ul>
  </nav>
</template>

<script>
import { ref, reactive, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'NavbarComponent',
  setup() {
    const store = useStore();
    const isMenuOpen = ref(false);
    const isSubmenuOpen = reactive({});
    const windowWidth = ref(window.innerWidth);

    const menu = computed(() => {
      return store.state.menuData.filter(item => {
        if (!item.showInMenu) {
          return false;
        }
        if (item.requiresAuth && !store.getters.isLoggedIn) {
          return false;
        }
        if (item.submenu) {
          item.submenu = item.submenu.filter(subitem => subitem.showInMenu && (!subitem.requiresAuth || store.getters.isLoggedIn));
        }
        return true;
      });
    });

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const toggleSubmenu = (itemName) => {
      if (windowWidth.value <= 768) {
        isSubmenuOpen[itemName] = !isSubmenuOpen[itemName];
      }
    };

    const closeMenu = () => {
      isMenuOpen.value = false;
      Object.keys(isSubmenuOpen).forEach(key => {
        isSubmenuOpen[key] = false;
      });
    }

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      if (windowWidth.value > 768) {
        isMenuOpen.value = false;
        Object.keys(isSubmenuOpen).forEach(key => {
          isSubmenuOpen[key] = false;
        });
      }
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      isMenuOpen,
      isSubmenuOpen,
      windowWidth,
      menu,
      toggleMenu,
      toggleSubmenu,
      closeMenu
    };
  }
};
</script>

<style scoped>
.navbar {
  background-color: #9400ff;
  overflow: visible;
  min-height: 31px;
  display: inline-flex;
  flex-direction: column;
  width: auto;
  margin: 0.1em 0.75em 9px 0.75em;
  box-shadow: 0 0 2px 5px #9400ff;
}

.menu-toggle {
  background-color: #9400ff;
  color: white;
  border: none;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  display: none;
  font-weight: bold;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar li>span {
  display: block;
  color: white;
  text-align: center;
  padding: 6px 20px;
  text-decoration: none;
  font-weight: bold;
}

.navbar a:hover {
  background-color: #7a00d1;
}

.menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.dropdown-content {
  position: absolute;
  background-color: #9400ff;
  min-width: 200px;
  z-index: 1;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  box-shadow: 2px 2px 4px #666;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #7a00d1;
}

.navbar li:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }

  .navbar ul {
    flex-direction: column;
  }

  .navbar li {
    width: 100%;
  }

  .navbar a,
  .navbar li>span {
    text-align: left;
    padding: 14px 20px;
  }

  .menu-toggle {
    display: block;
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    display: none;
    padding-left: 1em;
  }

  .navbar li:hover .dropdown-content {
    display: block;
  }
}

.pointer {
  cursor: pointer;
}
</style>
