import { createApp } from 'vue';
import AppComponent from './AppComponent.vue';
import router from './router';
import store from './store';
import axios from './axios';
import './assets/css/editor.css';

async function fetchMenuData() {
  const response = await fetch(process.env.VUE_APP_BACKEND_URL + '/menu-data');
  return await response.json();
}

fetchMenuData().then(menuData => {
  store.commit('setMenuData', menuData);
});

const app = createApp(AppComponent);
app.use(router);
app.use(store);
app.config.globalProperties.$axios = axios;
app.mount('#app');
